var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "van-collapse",
            {
              attrs: { accordion: "" },
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "van-collapse-item",
                { key: index, attrs: { name: index, title: item.name } },
                [
                  _c("div", { staticClass: "shadow" }, [
                    _c("p", { staticClass: "list" }, [
                      _c("span", [_vm._v("城市：")]),
                      _c("span", [_vm._v(_vm._s(item.areaName))]),
                    ]),
                    _c("p", { staticClass: "list" }, [
                      _c("span", [_vm._v("统一社会信用代码:")]),
                      _c("span", [_vm._v(_vm._s(item.creditCode))]),
                    ]),
                    _c("p", { staticClass: "list" }, [
                      _c("span", [_vm._v("注册号：")]),
                      _c("span", [_vm._v(_vm._s(item.regNumber))]),
                    ]),
                    _c("p", { staticClass: "list" }, [
                      _c("span", [_vm._v("营业状态：")]),
                      _c("span", [_vm._v(_vm._s(item.businessStatus))]),
                    ]),
                    _c("p", { staticClass: "list" }, [
                      _c("span", [_vm._v("法定代表人：")]),
                      _c("span", [_vm._v(_vm._s(item.faRen))]),
                    ]),
                    _c("p", { staticClass: "list" }, [
                      _c("span", [_vm._v("地址：")]),
                      _c("span", [_vm._v(_vm._s(item.address))]),
                    ]),
                    _c("p", { staticClass: "list" }, [
                      _c("span", [_vm._v("经营项目：")]),
                      _c("span", [_vm._v(_vm._s(item.bussinessDes))]),
                    ]),
                    _c("p", { staticClass: "list" }, [
                      _c("span", [_vm._v("注册资金：")]),
                      _c("span", [_vm._v(_vm._s(item.regM) + "万元")]),
                    ]),
                    _c("p", { staticClass: "list" }, [
                      _c("span", [_vm._v("经营期限：")]),
                      _c("span", [_vm._v(_vm._s(item.issueTime))]),
                    ]),
                    _c("p", { staticClass: "list" }, [
                      _c("span", [_vm._v("登记机关：")]),
                      _c("span", [_vm._v(_vm._s(item.regOrgName))]),
                    ]),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }