<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" :title="title" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="$router.back()"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
      <!-- <fb-button slot="right" icon="menu" size="small" type="primary">
      </fb-button> -->
    </fb-header>
    <!-- 默认插槽 -->
    <div>
      <van-collapse v-model="activeNames" accordion>
        <van-collapse-item
          v-for="(item, index) in list"
          :key="index"
          :name="index"
          :title="item.name"
        >
          <div class="shadow">
            <p class="list">
              <span>城市：</span>
              <span>{{ item.areaName }}</span>
            </p>
            <p class="list">
              <span>统一社会信用代码:</span>
              <span>{{ item.creditCode }}</span>
            </p>
            <p class="list">
              <span>注册号：</span>
              <span>{{ item.regNumber }}</span>
            </p>
            <p class="list">
              <span>营业状态：</span>
              <span>{{ item.businessStatus }}</span>
            </p>
            <p class="list">
              <span>法定代表人：</span>
              <span>{{ item.faRen }}</span>
            </p>
            <p class="list">
              <span>地址：</span>
              <span>{{ item.address }}</span>
            </p>
            <p class="list">
              <span>经营项目：</span>
              <span>{{ item.bussinessDes }}</span>
            </p>
            <p class="list">
              <span>注册资金：</span>
              <span>{{ item.regM }}万元</span>
            </p>
            <p class="list">
              <span>经营期限：</span>
              <span>{{ item.issueTime }}</span>
            </p>
            <p class="list">
              <span>登记机关：</span>
              <span>{{ item.regOrgName }}</span>
            </p>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </fb-page>
</template>

<script>
import { caseBase } from '~api-config'
export default {
  name: '',
  data() {
    return {
      title: '工商信息',
      activeNames: '',
      list: []
    }
  },
  watch: {
    activeNames(state) {
      if (state || state === 0) {
        this.$axios
          .get(caseBase + '/management/business?name=' + this.list[state].name)
          .then(response => {
            const key = response.data[0].companyName
            this.$axios
              .get(caseBase + '/management/getInfoByKey?companyName=' + key)
              .then(response => {
                const data = response.data
                const infoList = this.list.map(item => {
                  if (item.companyName === this.list[state].name) {
                    data.name = this.list[state].name
                    item = data
                  }
                  return item
                })
                this.list = infoList
              })
          })
      }
    }
  },
  created() {
    this.$axios
      .get(
        caseBase +
          '/lts/litigant/queryLitigantListByCaseId?caseId=' +
          this.$route.params.caseId
      )
      .then(response => {
        let name = response.data.data.filter(
          x => x.category !== 'NATURAL_PERSON'
        )
        name = name.map(x => {
          x.companyName = x.name
          return x
        })
        if (name.length === 0) {
          this.$toast('无数据')
        } else {
          this.list = name
        }
      })
  }
}
</script>

<style lang="stylus" scoped>
.list
  display flex
  justify-content space-between
  border-bottom 0.026667rem solid #ccc
  padding 10px
.list span:last-child
  text-align right
  width 60%
</style>
